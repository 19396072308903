import Head from "next/head";
import React from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import IntuChargeLogoLight from "../Icons/IntuChargeLogoLight";
import { defaultRedirect } from "../utils/defaultRedirect";
import { RootState } from "../Store/Store";

const PageNotFound: React.FC = (): JSX.Element => {
  const router = useRouter();
  const permissions = useSelector((state: RootState) => state.auth.user.permissions);
  const role = useSelector((state: RootState) => state.auth.user?.profile?.role_sc);
  const handleRedirect = () => {
    const link = defaultRedirect(permissions, role);
    router.push(link || "/");
  };
  return (
    <>
      <Head>
        <title>404: Page not Found</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content="404 page" />
      </Head>
      <div className="flex h-[85vh] flex-col bg-transparent">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            {/* <Link href="/dashboard"> */}
            <span onClick={handleRedirect} aria-hidden className="inline-flex">
              <span className="sr-only">
                <span>Intuions</span>
              </span>
              <IntuChargeLogoLight />
            </span>
            {/* </Link> */}
          </div>
          <div className="py-16">
            <div className="text-center">
              <h5 className="text-primary-main text-h5">404</h5>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                {/* <Link href="/dashboard"> */}
                <span
                  onClick={handleRedirect}
                  aria-hidden
                  className="text-base font-medium text-primary-main cursor-pointer"
                >
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </span>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default PageNotFound;
